import DeleteIcon from "@material-ui/icons/Delete";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { alpha } from '@material-ui/core/styles'
import ReactDOM from "react-dom";
import TextField from "@material-ui/core/TextField";
import PropTypes from "prop-types";
import AddCircleOutlinedIcon from "@material-ui/icons/AddCircleOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import NavigationIcon from "@material-ui/icons/Navigation";
import MoreVertOutlinedIcon from "@material-ui/icons/MoreVertOutlined";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useContext, useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import ExtractorType from "../../models/enums/ExtractorType";
import ExtractorDto from "../../models/Extractor";
import ExtractorService from "../../services/ExtractorService";
import GenericModal from "../shared/GenericModal";
import GenericModalAsk from "../shared/GenericModalAsk";
import ExtractorActionType from "../../models/enums/ExtractorActionType";
import AppContext from "../../context/AppContext";

const { lightBlue } = require("@material-ui/core/colors");

const useStyles = makeStyles((theme) => ({
  link: {
    "& > * + *": {
      marginLeft: theme.spacing(1),
    },
  },
  title: {
    marginTop: -3,
    marginLeft: 5,
  },
  add: {
    marginRight: -7,
  },
  "@keyframes pulse": {
    from: {
      borderColor: alpha(theme.palette.secondary.dark, 0.75),
    },
    to: {
      borderColor: alpha(theme.palette.secondary.light, 1),
    },
  },
  executing: {
    borderStyle: "solid",
    border: 0,
    borderLeft: 1,
    borderLeftWidth: 5,
    borderColor: theme.palette.secondary.dark,
    animationName: "$pulse",
    animationDuration: "1000ms",
    animationIterationCount: "infinite",
    animationDirection: "alternate",
    animationTimingFunction: "ease-in-out",
    height: "41.52px",
  },
  error: {
    borderStyle: "solid",
    border: 0,
    borderLeft: 1,
    borderLeftWidth: 5,
    borderColor: theme.palette.error.light,
    height: "41.52px",
  },
  success: {
    borderStyle: "solid",
    border: 0,
    borderLeft: 1,
    borderLeftWidth: 5,
    borderColor: theme.palette.success.light,
    height: "41.52px",
  },
  stopped: {
    borderStyle: "solid",
    border: 0,
    borderLeft: 1,
    borderLeftWidth: 5,
    borderColor: theme.palette.yellow.light,
    height: "41.52px",
  },
}));

const defaultState = {
  name: "",
  description: "",
  viewerEmail: "",
  show: false,
  nameError: "",
  descriptionError: "",
  viewerEmailError: "",
  rename: false,
  extractorType: ""
};

const status = {
  READY: "READY",
  EXECUTING: "EXECUTING",
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  STOPPED: "STOPPED",
};

let intervalRef;

function ExtractorList(props) {
  const [state] = useContext(AppContext);
  const classes = useStyles();
  const history = useHistory();
  const intl = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState([]);
  const [extractorModal, setExtractorModal] = useState(defaultState);
  const [anchorEl, setAnchorEl] = useState();
  const [currentExtractor, setCurrentExtractor] = useState();
  const [showAskModal, setShowAskModal] = useState(false);
  const [askAction, setAskAction] = useState({});
  const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  const getMessageId = (messageId) => {
    let newMessageID;
    switch (props.extractorType) {
      case ExtractorType.INSERTOR:
        newMessageID = messageId.replace("extractor", "insertor");
        break;
      case ExtractorType.NAVIGATOR:
        newMessageID = messageId.replace("extractor", "navigator");
        break;
      default:
        newMessageID = messageId
    }
    
    return newMessageID;
  };

  const getExtractors = useCallback(() => {
    if (window.location.pathname.indexOf("dashboard") !== -1) {
        ExtractorService.getExtractors(props.extractorType).then(
          (response) => {
            const { data } = response;
            data.forEach((action) => {
              if (
                action.status === status.ERROR &&
                action.errorMessages.length > 0
              ) {
                setLoadingASK(false);
                enqueueSnackbar(action.errorMessages[0].error, {
                  variant: "error",
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left",
                  },
                });
                ExtractorService.clearErrorMessage(action.id).then(
                  () => {},
                  () => {
                    enqueueSnackbar(
                      intl.formatMessage({ id: getMessageId("extractor.list.error.execute") }),
                      { variant: "error" }
                    );
                  }
                );
              }
            });
            setRows(data);
            setTimeout(() =>
                ExtractorService.checkPendingAsk(props.extractorType).then(
                  (res) => {
                    if (res.data !== "") {
                      if (res.data.type === ExtractorActionType.NAVIGATE) {
                        res.data.updated = true;
                        ExtractorService.updatePendingAskAction(res.data);
                        setLoadingASK(false);
                      } else {
                        setAskAction(res.data);
                        setShowAskModal(true);
                      }
                    }
                  },
                  () => {
                    setLoadingASK(false);
                    enqueueSnackbar(
                      intl.formatMessage({ id: getMessageId("extractor.list.error.execute") }),
                      { variant: "error" }
                    );
                  }
                ), 1000);
          },
          (error) => {
            enqueueSnackbar(
              intl.formatMessage(
                { id: "error.unknown" },
                { errorMessage: error?.message }
              ),
              { variant: "error" }
            );
          }
        );
    }
  }, []);

  const backdropASK = (
    <Backdrop open style={{ zIndex: "100", color: "#fff" }}>
      <CircularProgress style={{ color: lightBlue[300] }} />
    </Backdrop>
  );
  //ReactDOM.render(backdropASK, document.getElementById("backdropASK"));
  const setLoadingASK = (value) => {
    if (value) {
      ReactDOM.render(backdropASK, document.getElementById("backdropASK"));
    } else {
      ReactDOM.render(<></>, document.getElementById("backdropASK"));
    }
  };

  useEffect(async () => {

    // We want to avoid both refresh to occur at the exact same time. It can create conflict when the
    // access token expires and needs to be refreshed. A better solution would be to have a single call
    // to refresh insertor/extractor/screen-share/dataset/schedule/query at the same time... And maybe even adding
    // askPendingAskInfo to this
    if (props.extractorType === ExtractorType.INSERTOR) {
      const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await wait(1000);
    }

    if (props.extractorType === ExtractorType.NAVIGATOR) {
      const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
      await wait(500);
    }

    getExtractors();
    intervalRef = setInterval(getExtractors, state.listRefreshInterval);
    return () => {
      clearInterval(intervalRef);
    };
  }, [getExtractors]);

  const handleNameModal = (event) =>
    setExtractorModal((prevState) => ({
      ...prevState,
      name: event.target.value,
    }));

  const handleDescriptionModal = (event) =>
    setExtractorModal((prevState) => ({
      ...prevState,
      description: event.target.value,
    }));

  const handleViewerEmailModal = (event) =>
    setExtractorModal((prevState) => ({
      ...prevState,
      viewerEmail: event.target.value,
    }));

  const handleClick = (event, extractor) => {
    setAnchorEl(event.currentTarget);
    setCurrentExtractor(extractor);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const editExtractor = (row) => {
    ExtractorService.getExtractor(currentExtractor.id).then(
      (response) => {
        const { data } = response;
        history.push({
          pathname: props.extractorType === ExtractorType.EXTRACTOR ? "/extractor" :
                    props.extractorType === ExtractorType.INSERTOR ? "/insertor" : "/screen-share",
          extractorProps: data,
        });
      },
      () => {
        enqueueSnackbar(
          intl.formatMessage({ id: getMessageId("extractor.list.error.get") }),
          { variant: "error" }
        );
      }
    );
  };

  const deleteExtractor = (row) => {
    ExtractorService.deleteExtractor(row.id)
      .then(() => {
        const result = rows.filter((e) => e.id !== row.id);
        setRows(result);
        history.push("/scheduler");
      })
      .catch((error) => {
        handleErrorDelete(error);
      });
    setAnchorEl(null);
  };

  function handleErrorDelete(error) {
    if (error.response.data.message.includes("ConstraintViolationException")) {
      props.setOpen(true);
      props.setMessage(
        "Could not delete this extractor, it is associated with a data set"
      );
    }
  }

  function isEmail(value) {
        const regexp = new RegExp(/[^\s]*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
        return regexp.test(value);
  }

  const clickToExtractor = () => {
    let success = true;
    let newState = { ...extractorModal, nameError: "", descriptionError: "", viewerEmailError: "" };

    setExtractorModal((prevState) => ({
      ...prevState,
      nameError: "",
      descriptionError: "",
      viewerEmailError: "",
    }));

    if (extractorModal.name.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        nameError: intl.formatMessage({ id: getMessageId("extractor.list.error.name") }),
      };
    }

    if (extractorModal.description.trim().length === 0) {
      success = false;
      newState = {
        ...newState,
        descriptionError: intl.formatMessage({
          id: getMessageId("extractor.list.error.description"),
        }),
      };
    }

    if (props.extractorType === ExtractorType.NAVIGATOR && (
      extractorModal.viewerEmail.trim().length === 0 || !isEmail(extractorModal.viewerEmail.trim()))) {
      success = false;
      newState = {
        ...newState,
        viewerEmailError: intl.formatMessage({
          id: getMessageId("navigator.list.error.viewerEmail"),
        }),
      };
    }

    const findById = (element) => element.id === currentExtractor.id;

    if (success) {
      const extractorDto = new ExtractorDto(
        extractorModal.name,
        extractorModal.description,
        extractorModal.viewerEmail,
        props.extractorType
      );
      if (extractorModal.rename) {
        extractorDto.id = currentExtractor.id;
        ExtractorService.rename(extractorDto)
          .then(() => {
            const newRowsState = rows;
            newRowsState[rows.findIndex(findById)].name = extractorDto.name;
            setRows(newRowsState);
            enqueueSnackbar(
              intl.formatMessage({ id: getMessageId("extractor.list.success") }),
              { variant: "success" }
            );
            setExtractorModal({ ...defaultState, show: false });
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              enqueueSnackbar(error.response.data.message,
                { variant: "error" });
            } else {
              newState = {
                ...newState,
                nameError: intl.formatMessage({
                  id: getMessageId("extractor.list.error.unique"),
                }),
              };
          }
            setExtractorModal(newState);
          });
      } else {
        ExtractorService.start(extractorDto)
          .then(() => {
            history.push({
              pathname: props.extractorType === ExtractorType.EXTRACTOR ? "/extractor" :
              props.extractorType === ExtractorType.INSERTOR ? "/insertor" : "/screen-share",
              extractorProps: {
                name: extractorModal.name,
                description: extractorModal.description,
                viewerEmail: extractorModal.viewerEmail,
                extractorType: props.extractorType
              },
            });
          })
          .catch((error) => {
            if (error?.response?.data?.message) {
              enqueueSnackbar(error.response.data.message,
                { variant: "error" });
            } 
            if (error?.response.status === 409) {            
              newState = {
                ...newState,
                nameError: intl.formatMessage({
                  id: getMessageId("extractor.list.error.unique"),
                }),
              };
            } else {
              enqueueSnackbar(
                intl.formatMessage(
                  { id: "error.unknown" },
                  { errorMessage: error?.message }
                ),
                { variant: "error" }
              );

            }

            setExtractorModal(newState);
          });
      }
    } else {
      setExtractorModal(newState);
    }
  };

  const openModalAdd = () => {
    setExtractorModal({ ...defaultState, show: true });
  };

  const componentSelectAction = (
    <>
      <FormattedMessage id={getMessageId("extractor.list.modal.name")}>
        {(text) => (
          <TextField
            autoFocus
            margin="normal"
            required
            fullWidth
            id="nameModal"
            name="nameModal"
            value={extractorModal.name}
            inputProps={{ maxLength: 128 }}
            label={text}
            error={extractorModal.nameError !== ""}
            helperText={extractorModal.nameError}
            onChange={handleNameModal}
          />
        )}
      </FormattedMessage>
      <FormattedMessage id={getMessageId("extractor.list.modal.description")}>
        {(text) => (
          <TextField
            multiline
            minRows={3}
            maxRows={4}
            margin="normal"
            required
            fullWidth
            id="descriptionModal"
            name="descriptionModal"
            value={extractorModal.description}
            inputProps={{ maxLength: 1024 }}
            label={text}
            error={extractorModal.descriptionError !== ""}
            helperText={extractorModal.descriptionError}
            onChange={handleDescriptionModal}
          />
        )}
      </FormattedMessage>
      {props.extractorType === ExtractorType.NAVIGATOR ? !extractorModal.rename ? (
        <FormattedMessage id={getMessageId("navigator.list.modal.viewerEmail")}>
        {(text) => (
          <TextField
            margin="normal"
            required
            fullWidth
            id="viewerEmailModal"
            name="viewerEmailModal"
            value={extractorModal.viewerEmail}
            inputProps={{ maxLength: 255 }}
            label={text}
            error={extractorModal.viewerEmailError !== ""}
            helperText={extractorModal.viewerEmailError}
            onChange={handleViewerEmailModal}
          />
        )}
      </FormattedMessage>
      ) : (
        <FormattedMessage id={getMessageId("navigator.list.modal.viewerEmail")}>
        {(text) => (
          <TextField
            margin="normal"
            required
            fullWidth
            id="viewerEmailModal"
            name="viewerEmailModal"
            value={extractorModal.viewerEmail}
            inputProps={{ maxLength: 255 }}
            label={text}
            error={extractorModal.viewerEmailError !== ""}
            helperText={extractorModal.viewerEmailError}
            disabled
          />
        )}
      </FormattedMessage>
      ) : (<></>)}
    </>
  );

  const renameExtractor = () => {
    setExtractorModal({
      ...defaultState,
      show: true,
      rename: true,
      name: currentExtractor.name,
      description: currentExtractor.description,
      viewerEmail: currentExtractor.viewerEmail
    });
    setAnchorEl(null);
  };

  const execute = () => {
    ExtractorService.checkPendingAsk(props.extractorType).then(
      (res) => {
        if (res.data === "") {
          ExtractorService.execute(currentExtractor.id).then(
            () => {
              setRows(
                rows.map((r) => {
                  if (currentExtractor.id === r.id) r.status = status.EXECUTING;
                  return r;
                })
              );
            },
            (error) => {
              if (error?.response?.data?.message) {
                enqueueSnackbar(error.response.data.message,
                  { variant: "error" });
                
              } else {
                enqueueSnackbar(
                  intl.formatMessage({ id: getMessageId("extractor.list.error.execute") }),
                  { variant: "error" }
                );
              }
            }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage({ id: getMessageId("extractor.list.pending.ask") }),
            { variant: "warning" }
          );
        }
      },
      () => {
        enqueueSnackbar(
          intl.formatMessage({ id: getMessageId("extractor.list.error.execute") }),
          { variant: "error" }
        );
      }
    );
    setAnchorEl(null);
  };

  const handleModalClose = () => {
    setExtractorModal((prevState) => ({ ...prevState, show: false }));
  };

  const handleModalAskClose = () => {
    setShowAskModal(false);
  };

  const getModalTitle = () => {
    if (extractorModal.rename) return getMessageId("extractor.list.modal.title.rename");
    return getMessageId("extractor.list.modal.title.add");
  };

  const getTableRowStyle = (extractorStatus) => {
    if (extractorStatus === status.EXECUTING) return classes.executing;
    if (extractorStatus === status.SUCCESS) return classes.success;
    if (extractorStatus === status.ERROR) return classes.error;
    if (extractorStatus === status.STOPPED) return classes.stopped;

    return "";
  };

  const showConfirmDeleteModal = (row) => {
    setSelectedRow(row);
    setShowModalConfirmDelete(true);
  }

  const componentConfirmDelete = (
    <Typography>
      <FormattedMessage id={getMessageId("extractor.modal.delete.text")} />
    </Typography>
  );

  const clickConfirmDelete = () => {
    deleteExtractor(selectedRow);
  };

  const closeModalConfirmDelete = () => {
    setShowModalConfirmDelete(false);
  };

  return (
    <>
      {showModalConfirmDelete && (
          <GenericModal
            title={intl.formatMessage({ id: "generic.modal.confirm" })}
            component={componentConfirmDelete}
            click={clickConfirmDelete}
            handleClose={closeModalConfirmDelete}
          />
        )}
      <Toolbar variant="dense">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <Box display="flex" ml="-10px">
              <Tooltip
                title={intl.formatMessage({ id: getMessageId("extractor.list.info") })}
              >
                {props.extractorType === ExtractorType.EXTRACTOR ||
                props.extractorType === ExtractorType.INSERTOR ?
                   (<ListAltOutlinedIcon color="secondary" />) :
                    (<NavigationIcon color="secondary" />) }
              </Tooltip>
              <Typography variant="h6" className={classes.title}>
                <FormattedMessage id={getMessageId("dashboard.extractor")} />
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              color="secondary"
              onClick={openModalAdd}
              className={classes.add}
            >
              <Tooltip
                title={intl.formatMessage({ id: getMessageId("extractor.list.add.new") })}
              >
                <AddCircleOutlinedIcon />
              </Tooltip>
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <Divider />
      <TableContainer>
        <Table size="small">
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.name}
                className={getTableRowStyle(row.status)}
                title={row.errorMessage}
              >
                <TableCell align="left" scope="row">
                  <Typography variant="body2">{row.name}</Typography>
                </TableCell>
                <TableCell align="right" style={{ whiteSpace: "nowrap" }}>
                  {row.status !== status.EXECUTING && (
                    <>
                      <DeleteIcon
                        onClick={() => {
                          showConfirmDeleteModal(row);
                        }}
                        style={{ cursor: "pointer" }}
                      />
                      <IconButton
                        onClick={(e) => {
                          handleClick(e, row);
                        }}
                        size="small"
                        style={{ marginTop: "-15px" }}
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                    </>
                  )}
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {props.extractorType !== ExtractorType.NAVIGATOR ? (
                      <MenuItem onClick={() => execute()}>
                      <FormattedMessage id="dashboard.execute" />
                    </MenuItem>
                    ) : (<></>)}
                    
                    {/* <MenuItem onClick={handleClose}>
                      <FormattedMessage id="dashboard.copy" />
                    </MenuItem> */}
                    <MenuItem onClick={() => renameExtractor()}>
                      <FormattedMessage id="dashboard.rename" />
                    </MenuItem>
                    {props.extractorType !== ExtractorType.NAVIGATOR ? (
                    <MenuItem onClick={() => editExtractor(row)}>
                      <FormattedMessage id="dashboard.edit" />
                    </MenuItem>
                    ) : (<></>)}
        
                    {/* <MenuItem onClick={() => deleteExtractor()}>
                      <FormattedMessage id="dashboard.remove" />
                    </MenuItem> */}
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {extractorModal.show && (
        <FormattedMessage id={getModalTitle()}>
          {(text) => (
            <GenericModal
              title={text}
              component={componentSelectAction}
              click={clickToExtractor}
              handleClose={handleModalClose}
            />
          )}
        </FormattedMessage>
      )}
      {showAskModal && (
        <GenericModalAsk
          askAction={askAction}
          handleClose={handleModalAskClose}
        />
      )}
    </>
  );
}

ExtractorList.propTypes = {
  setMessage: PropTypes.func,
  setOpen: PropTypes.func,
  extractorType: PropTypes.string
};

ExtractorList.defaultProps = {
  setMessage: () => {},
  setOpen: () => {},
  extractorType: null
};

export default ExtractorList;
