/* eslint-disable react/jsx-boolean-value */
import {
  Box,
  CardContent,
  CardHeader,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/es/Button/Button";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";
import Container from "@material-ui/core/es/Container/Container";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import TextField from "@material-ui/core/esm/TextField/TextField";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import React, { useEffect, useState, useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import AuthenticationService from "../../services/AuthenticationService";
import LocalStorageService from "../../services/LocalStorageService";
import UserService from "../../services/UserService";
import Copyright from "./Copyright";

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  card: {
    marginTop: theme.spacing(12),
  },
  cardHeader: {
    marginTop: theme.spacing(6),
  },
  cardContent: {
    margin: theme.spacing(2, 2, 2, 2),
  },
  submit: {
    marginTop: theme.spacing(6),
  },
  textField: {
    fullWidth: true,
  },
}));

function Login() {
  const history = useHistory();
  const classes = useStyles();
  const intl = useIntl();
  const [email, setEmail] = useState(LocalStorageService.getRememberMeUser());
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(LocalStorageService.getRememberMeUser() ? true : false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const queryParameters = new URLSearchParams(location.search);

  const handleEmail = (event) => setEmail(event.target.value);

  const handlePassword = (event) => setPassword(event.target.value);
  const handleRememberMe = () => {
    setRememberMe(!rememberMe);
    if (rememberMe) {
      LocalStorageService.removeRememberMeUser();
    }
  };

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (queryParameters.get("errorToken")) {
      enqueueSnackbar(intl.formatMessage({ id: "login.user.expired" }), {
        variant: "error",
      });
    }
  }, []);

  const loadUserDetails = () => {
    UserService.getUserDetails().then(
        (response) => {
            const { data } = response;
            LocalStorageService.setUsername(data.username);
            LocalStorageService.setUserId(data.id)
        },
        () => {
                    enqueueSnackbar(intl.formatMessage({ id: "user.not-found" }), {
                        variant: "error",
                    });
                }
    );
  };

  const login = () => {
    if (email.length === 0) {
      enqueueSnackbar("Please type the email address", {
        variant: "error",
      });
      return;
    }

    if (password.length === 0) {
      enqueueSnackbar("Please type the password", {
        variant: "error",
      });
      return;
    }

    AuthenticationService.login(email, password)
      .then((response) => {
        const { data } = response;
        
        LocalStorageService.setRememberMeUser(rememberMe ? email : "");
        LocalStorageService.setAccessToken(data.accessToken);
        LocalStorageService.setRefreshToken(data.refreshToken);
        LocalStorageService.setUsername(data.username);
        LocalStorageService.setTempPasswordSet(data.tempPasswordSet);

        sessionStorage.setItem("Auth", "true");

        if (!data.enabled) {
          enqueueSnackbar(intl.formatMessage({ id: "login.user.disabled" }), {
            variant: "error",
          });
        } else if (data.daysToExpire === 0) {
          enqueueSnackbar(
            intl.formatMessage({ id: "login.password.must.changed" }),
            { variant: "warning" }
          );
          const json = {
            needPassword: true,
          };
          loadUserDetails();
          history.push({
            pathname: "/settings",
            settingsProps: json,
          });
        } else if (data.daysToExpire > 0 && data.daysToExpire <= 14) {
          enqueueSnackbar(
            intl.formatMessage(
              { id: "login.password.will.expire" },
              { days: data.daysToExpire }
            ),
            { variant: "warning" }
          );
          loadUserDetails();
          history.push("/");
        } else {
          const token = LocalStorageService.getNavigatorToken();
          if (token !== null) {
            LocalStorageService.removeNavigatorToken();
            history.push("/screen-share-viewer/" + token);
          } else {
            loadUserDetails();
            history.push("/");
          }
        }
      })
      .catch((error) => {
        if (error?.response?.data === "INVALID_CREDENTIALS") {
          enqueueSnackbar(
            intl.formatMessage({ id: "login.password.username.incorrect" }),
            { variant: "error" }
          );
        } else {
          enqueueSnackbar(
            intl.formatMessage(
              { id: "error.unknown" },
              { errorMessage: error?.message }
            ),
            { variant: "error" }
          );
        }
      });
  };

  const handleClick = () => {
    login();
  };
  const changeSignUpPage = () => {
    history.push("/signUp");
  };

  const changePageForgotPassword = () => {
    history.push("/forgotPassword");
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Container component="main" maxWidth="xs">
        <Fade in timeout={500}>
          <Card className={classes.card} onKeyDown={handleKeyDown}>
            <CardHeader
              className={classes.cardHeader}
              align="center"
              title={<FormattedMessage id="login.title" />}
              subheader={<FormattedMessage id="login.subtitle" />}
            />
            <CardContent className={classes.cardContent}>
              <form noValidate>
                <FormattedMessage id="login.email">
                  {(text) => (
                    <TextField
                      autoFocus
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label={text}
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={handleEmail}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircleOutlinedIcon
                              color={email ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>

                <FormattedMessage id="login.password">
                  {(text) => (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label={text}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={handlePassword}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockOutlinedIcon
                              color={password ? "secondary" : "inherit"}
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleClickShowPassword}>
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                </FormattedMessage>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <FormattedMessage id="login.remember.me">
                    {(text) => (
                      <FormControlLabel
                        control={
                          <Checkbox value="remember" color="secondary" />
                        }
                        onChange={handleRememberMe}
                        checked={rememberMe}
                        label={text}
                      />
                    )}
                  </FormattedMessage>

                  <Button
                    onClick={changePageForgotPassword}
                    color="secondary"
                    variant="text"
                  >
                    <FormattedMessage id="login.forgot" />
                  </Button>
                </Grid>

                <Button
                  type="button"
                  fullWidth
                  size="large"
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={handleClick}
                >
                  <Typography>
                    <FormattedMessage id="login.sign.in" />
                  </Typography>
                </Button>
              </form>
            </CardContent>
          </Card>
        </Fade>
        <Box mt={4}>
          <FormattedMessage id="login.register">
            {(text) => (
              <FormattedMessage id="login.registerLink">
                {(textLink) => (
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    align="center"
                  >
                    {`${text} `}
                    <Button
                      onClick={changeSignUpPage}
                      color="secondary"
                      variant="text"
                    >
                      {textLink}
                    </Button>
                  </Typography>
                )}
              </FormattedMessage>
            )}
          </FormattedMessage>
        </Box>
        <Box mt={4}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

export default Login;
